<template lang="pug">
    el-dialog( title="Zi libera" :visible.sync="showPopup" class="my-dialog-class" )
        el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            h1(v-if="mode=='edit'") Numar Document: {{selectedObject.Id}}
            el-row( :gutter="15" )
                el-col( :span="8" )
                    el-form-item( label="Angajat"  :required="true" )
                        span 
                            strong {{Info.Angajat.Nume}}
                
                el-col( :span="8" )
                    el-form-item( label="Start concediu" prop="StartConcediu"  :required="true" )
                        el-date-picker.full-width( v-model="selectedObject.StartConcediu" value-format="yyyy-MM-dd" )
                
                el-col( :span="8" )
                    el-form-item( label="Sfarsit concediu" prop="SfarsitConcediu"  :required="true" )
                        el-date-picker.full-width( v-model="selectedObject.SfarsitConcediu"  value-format="yyyy-MM-dd")
                
                el-col( :span="24" )
                    el-form-item( label="Tip zi libera" prop="IdTip"  :required="true" )
                        el-select.full-width( v-model="selectedObject.IdTip" )
                            el-option( v-for="a in Info.TipuriZileLibere" :key="'tip-'+a.Id" :value="a.Id" :label='a.Nume' )
 
        span( slot="footer" class="dialog-footer" )
            el-button( type='danger' @click="sterge" v-if="mode=='edit'") Sterge
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "tipuri-zile-libere-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      mode: 'add',
      Info:{
          Angajat: "",
          TipuriZileLibere: []
      },
      selectedObject: { Id: -1, IdAngajat: -1, StartConcediu:"", SfarsitConcediu: "", IdTip: -1 },
      rules: {
          Nume: [ { required: true, message: "Numele este obligatoriu" } ],
          StartConcediu:  [ { required: true, message: "Data este obligatorie" } ],
          SfarsitConcediu:  [ { required: true, message: "Data este obligatorie" } ],
          IdTip: [ { required: true, message: "Tipul este obligatoriu" } ],
      }
    }
  },
  methods: {
     
     show_me: async function( mode, selectedObject ) {
         
        this.showPopup             = true;
        this.mode                  = mode;
        
        var response = await this.post("zile_libere/get_info_for_dialog", { IdAngajat: selectedObject.IdAngajat });
        this.Info = response.Info;
        
         
        if( mode == 'add') 
        {
            this.selectedObject         = selectedObject;
            this.selectedObject.IdTip   = this.Info.TipuriZileLibere[0].Id;
        }
        else
        {
            console.log(selectedObject);
            var            result = await this.post("zile_libere/get_info_item_for_dialog", { id: selectedObject.Id } );
            this.selectedObject   = result.Item;
        }
    },
    save: async function() {
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            var response = await this.post("zile_libere/save", { mode: this.mode, object: this.selectedObject } );
            if( this.mode == 'add') 
            {
                this.$alert('Numar Document generat: ' + response.IdDocument, 'Id Document');
            }
            this.showPopup = false;
            this.$emit("save");
          }
        });
    },
    async sterge()
    {
        var confirmed = await this.$confirm('Sterg aceasta perioada?', 'Warning' );
        if( confirmed )
        {
            await this.post("zile_libere/sterge", { id: this.selectedObject.Id } );
            this.showPopup = false;
            this.$emit("save");
        }
    }
  },
  mounted: function() {
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
</style>